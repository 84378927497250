import React from "react";
import { Col } from "reactstrap";

import { Link } from "react-router-dom";
import "../../styles/car-item.css";

const CarItem = (props) => {
  const {id, image_path, model, name, automatic, speed, current_sale_price, discount_type, wholesale_minimum_qty, size } = props.item;
 
  return (
    <Col  lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img">
          <img src={`https://admin.rentacarsrilankahbs.com/${image_path}`} alt="" className="w-100" />
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{name}</h4>
          {/* <h6 className="rent__price text-center mt-">
            LKR{current_sale_price} <span>/ Day</span>
          </h6> */}

          <div className="car__list__view_item-info car__item-info d-flex align-items-center justify-content-between mt-1 mb-1" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
              <span className="d-flex flex-column align-items-center" style={{ marginBottom: '2px' }}>
                  <i className="ri-settings-2-line" style={{ fontSize: '24px' }}></i> 
                  {discount_type == 0 ? 'Manual' : 'Auto'}
              </span>
              
              <span className="d-flex flex-column align-items-center" style={{ marginBottom: '2px' }}>
                  <i className="ri-money-dollar-box-fill" style={{ fontSize: '24px' }}></i> 
                  Price List
              </span>
              
              <span className="d-flex flex-column align-items-center" style={{ marginBottom: '2px' }}>
                  <i className="ri-gas-station-fill" style={{ fontSize: '24px' }}></i> 
                  {size}
              </span>
          </div>

          
            <Link to={`/cars/${id}`}>
            <button className=" w-100 car__item-btn car__btn-rent">Rent</button>
            </Link>

            {/* <Link to={`/cars/${id}`}>
            <button className=" w-50 car__item-btn car__btn-details">Details</button>
            </Link> */}

          {/* <button className=" w-50 car__item-btn car__btn-details">
            <Link to={`/cars/${id}`}>Details</Link>
          </button> */}
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
